.upload--button::before {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 8px;
  background: url('/public/images/icon-plus.svg');
}

.files--list table tbody tr[data-status='Failed'] td {
  background: var(--negative--background);
}

.files--list table tbody tr td div.files--list--actions {
  display: flex;
  justify-content: center;
}

.files--list table tbody tr td.files--list--action-container {
  vertical-align: middle;
}

.files--list td.files--list--status {
  padding: 10px 0;
  vertical-align: middle;
}

.files--list--actions button {
  border: none;
  background-color: transparent;
}

.download-button-img {
  width: 20px;
  height: 25px;
  background: url('/public/images/icon-download.svg');
}

.files--list--none {
  background: url('/public/images/icon-file-large.svg');
}

.files--list--file-name {
  word-break: break-all;
}

@media (max-width: 720px) {
  .files--list .page-view-table tbody tr[data-status='Failed'] {
    background: var(--negative--background);
  }

  .files--list .page-view-table tbody td.files--list--file-name,
  .files--list .page-view-table tbody td.files--list--date,
  .files--list .page-view-table tbody td.files--list--uploaded-by {
    grid-column: 1;
  }

  .files--list .page-view-table tbody td.files--list--file-name {
    align-self: auto;
  }

  .files--list .page-view-table tbody td.files--list--date {
    grid-row: 2;
  }

  .files--list .page-view-table tbody td.files--list--uploaded-by {
    grid-row: 3;
  }

  .files--list .page-view-table tbody td.files--list--status {
    grid-row: 1;
    grid-column: 2;
    margin-bottom: 4px;
    padding: 0;
    justify-self: end;
  }

  .files--list table tbody tr td.files--list--action-container .action--column {
    justify-content: end;
  }
}
