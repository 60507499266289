.export--button::before {
  content: '';
  display: block;
  width: 20px;
  height: 25px;
  margin-right: 8px;
  background: url('/public/images/icon-download.svg');
}

.msis-request--list .page--heading {
  margin-bottom: 0;
}

.msis-request--list .page--content {
  flex-direction: column;
}

.msis-request--list .filter-container {
  display: flex;
  justify-content: space-between;
}

.msis-request--list .filter-dropdown {
  margin-bottom: 12px;
}

.msis-request--list .heading-buttons {
  gap: 24px;
  display: flex;
  align-items: center;
}

.msis-request--list .heading-buttons button {
  display: flex;
  max-height: 66px;
  min-height: 66px;
}

.msis-request--list .heading-buttons a {
  display: flex;
}

@media (max-width: 720px) {
  .msis-request--list table tbody td.msis-request--list--full-name,
  .msis-request--list table tbody td.msis-request--list--date-of-birth,
  .msis-request--list table tbody td.msis-request--list--msis-id {
    grid-column: 1;
  }

  .msis-request--list table tbody td.msis-request--list--full-name {
    align-self: center;
  }

  .msis-request--list table tbody td.msis-request--list--date-of-birth {
    grid-row: 2;
  }

  .msis-request--list table tbody td.msis-request--list--msis-id {
    grid-row: 3;
  }

  .msis-request--list table tbody td.msis-request--list--msis-status {
    grid-column: 2;
    justify-self: flex-end;
  }

  .msis-request--list table tbody tr td.msis-request--list--action-container .action--column {
    justify-content: end;
  }
}
